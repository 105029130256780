import React from "react";
import styled from "styled-components";
import Image from "react-shimmer";

const ModalImageWrapper = styled.div`
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 15px 0;
`;

const ModalImageViewer = ({ imageUrl, altName }) => {
  return (
    <ModalImageWrapper>
      <Image
        src={imageUrl}
        width={250}
        height="auto"
        alt={altName}
        style={{ objectFit: "cover", width: "100%" }}
      />
    </ModalImageWrapper>
  );
};

export default ModalImageViewer;
