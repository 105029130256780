import React, { Component } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import withDelayUnmounting from "./withDelayUnmounting";
import { searchingSchoolMessage, assetsURL } from "../../config";

const loading01 = `${assetsURL}/loader-01.gif`;
const loading02 = `${assetsURL}/loader-02.gif`;

const Wrapper = styled.div`
  height: 100%;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 123;
  overflow: hidden;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Progress = styled.img`
  max-width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 16px;
`;

const LoadingMessage = styled(Typography)`
  font-size: 17px !important;
  padding: 0 10px;
  background: #fff;

  @media screen and (min-width: 600px) {
    font-size: 20px !important;
  }
`;

class CircularLoading extends Component {
  state = {
    dataLoaded: false
  };

  componentDidUpdate(prevProps) {
    const { willUnmountNow } = this.props;

    if (!prevProps.willUnmountNow && willUnmountNow) {
      clearInterval(this.intervalId);
      this.setState({ dataLoaded: true });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const { dataLoaded } = this.state;

    return (
      <Wrapper>
        <LoadingContainer>
          <Progress
            src={dataLoaded ? loading02 : loading01}
            alt="loading image"
          />
          <LoadingMessage align="center">
            {searchingSchoolMessage[0]}
          </LoadingMessage>
          <LoadingMessage align="center">
            Do not close your browser.
          </LoadingMessage>
        </LoadingContainer>
      </Wrapper>
    );
  }
}

export default withDelayUnmounting(CircularLoading);
