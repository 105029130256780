import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Typography, Box } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import { createGTMEvent } from "../../../utils/googleTagManager";
import {
  FormBodyContainer,
  FormHeaderContainer,
  ComplianceNotice
} from "../../components";
import SchoolCard from "./SchoolCard";
import { pixelEventName } from "../../../config";
import { trackActions } from "../../../state/track";
import EddyClicksSchoolCard from "./EddyClicksSchoolCard";
import FriendRefer from "./FriendRefer";
class MatchResults extends Component {
  state = {};

  componentDidMount() {
    const { trackPixel, leadData } = this.props;

    window.scrollTo(0, 0);

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 4",
      action: "Load"
    });

    trackPixel({
      step: "4",
      eventName: pixelEventName.pageView,
      leadData
    });
  }

  onFinishButtonClicked = () => {
    const { widgetData } = this.props;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 4 Finish Button",
      action: "Click"
    });

    window.location.href =
      widgetData.confirmationPageUrl || widgetData.landingPageUrl;
  };

  removeDuplicateSchools = matches => {
    const uniqueSchoolsMap = new Map();

    for (const match of matches) {
      const schoolName = match.schoolName;
      uniqueSchoolsMap.set(schoolName, match);
    }

    const uniqueSchoolsArray = Array.from(uniqueSchoolsMap.values());

    return uniqueSchoolsArray;
  };

  filterSchools = (allMatches, selectedSchoolOptions = []) => {
    return {
      ...allMatches,
      count: selectedSchoolOptions.length,
      matches: allMatches.matches.filter(match =>
        selectedSchoolOptions.includes(match.schoolName)
      )
    };
  };

  render() {
    const { widgetData, allMatches, clickoutsMatches, selectedSchoolOptions } =
      this.props;
    const selectedSchools = this.filterSchools(
      allMatches,
      selectedSchoolOptions
    );
    return (
      <Fragment>
        <FormHeaderContainer className="form-header">
          <Typography variant="h5">Your Match Results</Typography>
        </FormHeaderContainer>
        <FormBodyContainer className="form-body">
          {/* {(!allMatches || !allMatches.count) && (
              <Typography>No matches found. Please try again.</Typography>
            )} */}

          <Box mb={2} pb={2} style={{ borderBottom: "1px solid #c9c9c9" }}>
            <Typography variant="h5">View Your Matches</Typography>
            <ComplianceNotice referringDomain={window.referringDomain || ""} />
          </Box>

          {clickoutsMatches && clickoutsMatches.length > 0 && (
            <Fragment>
              {clickoutsMatches.map((schoolInfo, index) => (
                <EddyClicksSchoolCard {...schoolInfo} key={index} />
              ))}
            </Fragment>
          )}
          <FriendRefer />
          {selectedSchools && selectedSchools.count > 0 && (
            <Fragment>
              {this.removeDuplicateSchools(selectedSchools.matches).map(
                (schoolInfo, index) => (
                  <SchoolCard {...schoolInfo} key={index} />
                )
              )}
            </Fragment>
          )}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
            align="right"
            pt={2}
          >
            <ComplianceNotice referringDomain={window.referringDomain || ""} />
            <Button
              variant="contained"
              color="primary"
              onClick={this.onFinishButtonClicked}
              id="finish-button"
            >
              {widgetData.submitButtonText || "Finish"}
              <KeyboardArrowRight />
            </Button>
          </Box>
        </FormBodyContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ widget, match, lead }) => {
  return {
    widgetData: widget.widgetData,
    allMatches: match.allMatches,
    clickoutsMatches: match.clickoutsMatches,
    leadData: lead.leadData,
    selectedSchoolOptions: match.selectedSchoolOptions
  };
};

const mapActionsToProps = {
  trackPixel: trackActions.trackPixel
};

export default connect(mapStateToProps, mapActionsToProps)(MatchResults);
