/* eslint-disable no-useless-escape */
export const getURLParameter = name => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const getDomainFromURL = (url = "") => {
  var result;
  var match;
  if (
    (match = url.match(
      /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
    ))
  ) {
    result = match[1];
    if ((match = result.match(/^[^\.]+\.(.+\..+)$/))) {
      result = match[1];
    }
  }
  return result;
};

export const getReferringDomainFullURL = url => {
  const paramString = url.split("?")[1];
  const queryString = new URLSearchParams(paramString);
  let fullUrl = "";
  let refDomain = "";
  for (let pair of queryString.entries()) {
    if (pair[0] === "refURL") {
      fullUrl = pair[1];
    }
    if (pair[0] === "refDomain") {
      refDomain = "https://" + pair[1];
    }
  }
  return fullUrl ? fullUrl : refDomain;
};

export const updateOrganicUrl = url => {
  try {
    const urlObj = new URL(url);
    const domain = getDomainFromURL(url);
    // Keywords to check for in the query parameters
    const keywords = [
      "utm_source",
      "utm_content",
      "sem_account_id",
      "sem_campaign_id",
      "sem_ad_id",
      "fbclid"
    ];
    const intro2HealthcareQueryParams = [
      { key: "utm_source", value: "google" },
      { key: "sem_account_id", value: "3659547981" },
      { key: "sem_campaign_id", value: "21313519398" },
      { key: "sem_ad_group_id", value: "162667028133" },
      { key: "sem_device_type", value: "m" },
      { key: "sem_keyword", value: "reputable healthcare programs" },
      { key: "sem_matchtype", value: "b" },
      { key: "sem_ad_id", value: "700509259352" },
      { key: "sem_network", value: "g" },
      { key: "sem_target_id", value: "kwd-1435626868053" },
      { key: "sem_feed_item_id", value: "" },
      { key: "utm_medium", value: "cpcnb" },
      { key: "utm_term", value: "reputable healthcare programs" },
      { key: "sem_placement", value: "" },
      { key: "sem_placement_category", value: "" },
      { key: "gad_source", value: "1" }
    ];
    const supportiveCollegeQueryParams = [
      {
        key: "utm_source",
        value: "facebook"
      },
      {
        key: "utm_medium",
        value: "social"
      },
      {
        key: "utm_campaign",
        value: "sc_national"
      },
      {
        key: "utm_id",
        value: "home-r"
      },
      {
        key: "utm_content",
        value: "FACEBOOK-SC-SOCIAL-007"
      }
    ];
    const hasKeyword = keywords.some(keyword =>
      urlObj.searchParams.has(keyword)
    );

    if (hasKeyword) {
      return url;
    } else {
      switch (domain) {
        case "intro2healthcare.com":
          intro2HealthcareQueryParams.forEach(param => {
            urlObj.searchParams.append(param.key, param.value);
          });
          break;
        default:
          supportiveCollegeQueryParams.forEach(param => {
            urlObj.searchParams.append(param.key, param.value);
          });
          break;
      }
      return urlObj.toString();
    }
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
};
