import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  .privacy-wrapper {
    padding-left: 25px;
    padding-top: 15px;
  }
  .privacy-link {
    margin-bottom: 10px;
  }
`;

const AIMPrivacyPolicy = () => (
  <Wrapper>
    <div className="privacy-wrapper">
      <a
        className="privacy-link"
        href="https://aviationmaintenance.edu/about-aviation-institute-of-maintenance/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      <br />
      <a
        href="https://aviationmaintenance.edu/your-rights/net-price-calculator/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Net Price Calculator
      </a>
    </div>
  </Wrapper>
);

export default AIMPrivacyPolicy;
