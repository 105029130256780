import React from "react";
import LogoDisplayFormat from "./LogoDisplayFormat";
import { Grid, Paper } from "@material-ui/core";
import FormField from "./FormField";
import ExclusiveTCPAText from "./ExclusiveTCPAText";

const TcpaCard = ({
  school,
  giveTcpaPermission,
  isTcpaDisclosureChecked,
  selectedSchoolIndex
}) => {
  return (
    <>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          margin: "20px 0",
          borderRadius: "8px"
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} sm={4}>
            <LogoDisplayFormat
              logoUrl={school.schoolLogoUrl}
              name={school.schoolName}
            />
          </Grid>
          <Grid item xs={8} sm={8}>
            <h5 className="show-description">{school.schoolName}</h5>
          </Grid>
        </Grid>
        <Grid>
          <FormField
            type="checkbox"
            id={`leadid_tcpa_disclosure_${selectedSchoolIndex}`}
            name="tcpaDisclosure"
            onClick={giveTcpaPermission}
            checked={isTcpaDisclosureChecked}
            label={
              <ExclusiveTCPAText tcpaConcentText={school.tcpaConcentText} />
            }
            value={school.tcpaConcentText}
            data-tf-element-role="consent-opt-in"
            trustedFormLabel="consent-language"
          />
        </Grid>
      </Paper>
    </>
  );
};

export default TcpaCard;
