import React from "react";
import {
  categories,
  aimPrograms,
  aimWidgetIdList,
  iecPrograms,
  iecWidgetIdList
} from "../../../config";

const ProgramOptions = ({ feedOptions, campusCode, widgetId }) => {
  let options = [];
  if (
    aimWidgetIdList.includes(widgetId) ||
    iecWidgetIdList.includes(widgetId)
  ) {
    const programs = iecWidgetIdList.includes(widgetId)
      ? iecPrograms[campusCode]
      : aimPrograms[campusCode];
    const optionElements = programs.map((program, index) => (
      <option key={index} value={program.id}>
        {program.name}
      </option>
    ));
    if (aimWidgetIdList.includes(widgetId)) {
      optionElements.push(
        <option key="200" value="200">
          Not Sure
        </option>
      );
    }

    options.push(...optionElements);
  } else {
    Object.keys(feedOptions).length &&
      Object.keys(feedOptions).forEach(categoryId => {
        if (feedOptions[categoryId].length) {
          let programs = [];
          feedOptions[categoryId].forEach(({ text, value }) =>
            programs.push(
              <option key={value} value={value}>
                {text}
              </option>
            )
          );
          options.push(
            <optgroup key={categoryId} label={categories[categoryId]}>
              {programs}
              <option key="1000" value="1000">
                I'm Not Sure
              </option>
            </optgroup>
          );
        }
      });
  }
  return options;
};

export default ProgramOptions;
