import React from "react";

export default function ExclusiveTCPAText({ tcpaConcentText }) {
  const parseTcpaConcentText = node => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName === "A") {
      return (
        <a
          key={node.href}
          href={node.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.textContent}
        </a>
      );
    } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName === "BR") {
      return <br />;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      return Array.from(node.childNodes).map((childNode, index) => (
        <React.Fragment key={index}>
          {parseTcpaConcentText(childNode)}
        </React.Fragment>
      ));
    }
    return null;
  };

  return (
    <React.Fragment>
      {parseTcpaConcentText(
        new DOMParser().parseFromString(tcpaConcentText, "text/html").body
      )}
    </React.Fragment>
  );
}
