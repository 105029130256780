import React from "react";

const TCPAText = () => {
  return (
    <>
      By checking this box, and providing my contact information, I am providing
      my electronic signature and consent to be contacted by the following
      selected school(s){" "}
      <span data-tf-element-role="contact-method">
        {" "}
        by email or telephone, including by text message{" "}
      </span>{" "}
      regarding educational services at the phone number above. I acknowledge
      these calls may be generated using an automated dialing system. I
      understand that I am under no obligation to provide consent to purchase
      any goods or services. Message and data rates may apply.
    </>
  );
};

export default TCPAText;
